import React from 'react';
// import DogVideo from '../img/video.mp4';

export const Video = () => (
  <div className="video">
    <div className="video__placeholder">
      <img src="/homepage/video/video-placeholder.png" alt="" />
    </div>
    <video className="video__media" autoPlay muted loop id="homepage-video">
      {/* <source src={DogVideo} type="video/mp4" /> */}
    </video>
  </div>
);
