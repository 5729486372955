import React from 'react';
import Img from 'gatsby-image';
import { IFImage, INameSlug } from '../interfaces/blog';
import { Video } from './Video';
import { HeroHomepage } from '../img/svg/HeroHomepage';

interface IProps {
  featured_media?: IFImage;
  tags?: INameSlug[] | [];
  title?: string;
  button?: string;
  video?: boolean;
  post?: boolean;
}

export const Hero: React.FC<IProps> = ({
  tags,
  title,
  button,
  video,
  post,
  featured_media,
}) => (
  <div className={`hero ${video ? 'hero--video' : ''}`}>
    {
      video ? (
        <Video />
      ) : (
        post ?
          <Img
            className="hero__image"
            fluid={featured_media && featured_media.localFile && featured_media.localFile.childImageSharp ? featured_media.localFile.childImageSharp.fluid : '#'}
            alt=""
          /> :
          <div className="hero__image">
            <div className="wrapper wrapper--full-width">
              <HeroHomepage />
            </div>
          </div>
      )
    }

    {title ? (
      <div className="hero__content">
        {tags && tags.length ?
          <span className="hero__tag tag tag--blue tag--font-white tag--uppercase">{tags[0].name}</span> :
          null}
        <h1 className="hero__title">
          {title}
        </h1>
      </div>) : null}
    {button ? (
      <div className="hero__button-link">
        <a className="btn btn--primary btn--link btn--fullWidth" href="https://porowneo.pl/ubezpieczenia/samochodu/ubezpieczenie-oc">{button}</a>
      </div>
    ) : null}
  </div>
);
